<template>
  <div>
    <SectionHeading :content="heading.content" :extra_content="heading.extra" />
    <SectionSecondaryHeading
      :content="secondary_heading.content"
      :extra_content="secondary_heading.extra"
      hr
    />

    <v-container class="support-section">
      <v-row class="d-flex justify-center">
        <v-col cols="12" xs="12" lg="6">
          <v-card class="mx-auto my-12" max-width="450" min-width="350">
            <v-card-title class="d-flex flex-column justify-center">
              <v-img
                height="62px"
                max-width="62px"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
              ></v-img>
              <span class="contact-title">Telegram</span>
            </v-card-title>
            <v-card-text class="text-center mt-3">
              <span class="contact-msg"
                >We provide 24/7 Support to anyone who reaches out to us on
                Telegram</span
              >
            </v-card-text>

            <v-card-text class="contactbtn text-center mt-2">
              <v-btn color="#4d73ff"> Contact Us </v-btn>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" lg="6">
          <v-card class="mx-auto my-12" max-width="450" min-width="350">
            <v-card-title class="d-flex flex-column justify-center">
              <v-img
                height="62px"
                max-width="62px"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Circle-icons-mail.svg/2048px-Circle-icons-mail.svg.png"
              ></v-img>
              <span class="contact-title">Email</span>
            </v-card-title>
            <v-card-text class="text-center mt-3">
              <span class="contact-msg"
                >Telegram doesn't work for you? No worries, you can write us an email.</span
              >
            </v-card-text>

            <v-card-text class="contactbtn text-center mt-2">
              <v-btn color="#4d73ff"> Contact Us </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionHeading from "../components/SectionHeading.vue";
import SectionSecondaryHeading from "../components/SectionSecondaryHeading.vue";
export default {
  name: "Dashboard",

  components: { SectionHeading, SectionSecondaryHeading },

  data: () => ({
    heading: {
      content: "Support",
      extra: [{ type: "text", text: "Available 24/7", color: "#2ECC71" }],
    },

    secondary_heading: {
      content: "We can help",
      extra:
        "If you're having any issues using the search tools, feel free to reach out to us in the support section, and we'll respond back to you as soon as we can.",
    },
  }),
};
</script>

<style lang="scss">
.support-section {
  max-width:950px !important;

  .contactbtn {
    button {
      color: white !important;
    }
  }

  .contact-title {
    margin-top: 15px;
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 500;
  }

  .contact-msg {
    font-size: 14px;
    font-family: "Ubuntu";
  }

  .contact-btn {
    font-size: 16px;
  }

  .v-card {
    min-height:300px !important;
    background: var(--v-support_cards_bg-base) !important;
    border-radius: 15px !important;
  }
}
</style>


